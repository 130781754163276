import { default as _91location_93UfB4RFlgfgMeta } from "/Users/baidu/www/hibachi/hibachinow/app/pages/booking/[location].vue?macro=true";
import { default as faqnfGRGfQ2W2Meta } from "/Users/baidu/www/hibachi/hibachinow/app/pages/faq.vue?macro=true";
import { default as gallerycxHB9w512BMeta } from "/Users/baidu/www/hibachi/hibachinow/app/pages/gallery.vue?macro=true";
import { default as indexB8e37lxaT8Meta } from "/Users/baidu/www/hibachi/hibachinow/app/pages/index.vue?macro=true";
import { default as locationssXcHg8xUFZMeta } from "/Users/baidu/www/hibachi/hibachinow/app/pages/locations.vue?macro=true";
export default [
  {
    name: "booking-location",
    path: "/booking/:location()",
    meta: _91location_93UfB4RFlgfgMeta || {},
    component: () => import("/Users/baidu/www/hibachi/hibachinow/app/pages/booking/[location].vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqnfGRGfQ2W2Meta || {},
    component: () => import("/Users/baidu/www/hibachi/hibachinow/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "gallery",
    path: "/gallery",
    meta: gallerycxHB9w512BMeta || {},
    component: () => import("/Users/baidu/www/hibachi/hibachinow/app/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexB8e37lxaT8Meta || {},
    component: () => import("/Users/baidu/www/hibachi/hibachinow/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "locations",
    path: "/locations",
    meta: locationssXcHg8xUFZMeta || {},
    component: () => import("/Users/baidu/www/hibachi/hibachinow/app/pages/locations.vue").then(m => m.default || m)
  }
]