
import * as netlifyRuntime$lpuPyBIqtG from '/Users/baidu/www/hibachi/hibachinow/node_modules/@nuxt/image/dist/runtime/providers/netlifyImageCdn'
import * as ipxRuntime$8aZzk9lFvb from '/Users/baidu/www/hibachi/hibachinow/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as ipxStaticRuntime$IXW5iZ4WE8 from '/Users/baidu/www/hibachi/hibachinow/node_modules/@nuxt/image/dist/runtime/providers/ipxStatic'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "avatar": {
      "modifiers": {
        "format": "webp",
        "width": 80,
        "height": 80
      }
    }
  },
  "provider": "ipxStatic",
  "domains": [
    "images.unsplash.com",
    "fakestoreapi.com",
    "res.cloudinary.com",
    "avatars.githubusercontent.com",
    "gravatar.com"
  ],
  "alias": {
    "/unsplash": "https://images.unsplash.com"
  },
  "densities": [
    1,
    2
  ],
  "format": [
    "webp, png, jpg",
    "webp"
  ]
}

imageOptions.providers = {
  ['netlify']: { provider: netlifyRuntime$lpuPyBIqtG, defaults: {"baseURL":"https://hibachinow.com"} },
  ['ipx']: { provider: ipxRuntime$8aZzk9lFvb, defaults: {} },
  ['ipxStatic']: { provider: ipxStaticRuntime$IXW5iZ4WE8, defaults: {} }
}
        